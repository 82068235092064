<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card class="elevation-5 pa-3">
                    <div class="text-right">
                        <localization />
                    </div>
                    <v-form autocomplete="on" @submit="login">
                        <v-card-text>
                            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                                {{ result }}
                            </v-snackbar>
                            <div class="layout column align-center">
                                <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                                <h1 class="text-center my-4 primary--text">
                                    Identification
                                </h1>
                                <div>Veuillez entrer votre email et votre mot de passe</div>
                            </div>
                            <v-text-field v-model="model.email" append-icon="mdi-at" name="email" :label="$t('login.email')"
                                type="email" required :rules="[rules.required, rules.email]" />
                            <v-text-field v-model="model.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                name="password" :label="$t('login.password')" :type="show1 ? 'text' : 'password'"
                                :rules="[rules.required]" required autocomplete="new-password"
                                @click:append="show1 = !show1" />
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn type="submit" color="primary" :loading="loading" block>
                                        Valider
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/forgottenPassword" block>
                                        {{ $t('login.forgottenPassword') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signup" block>
                                        {{ $t('login.signUp') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'SignIn',
    components: { Localization },
    data: () => ({
        result: '',
        color: 'undefined',
        show1: false,
        loading: false,
        showResult: false,
        model: {
            email: '',
            password: '',
        },
        rules: {
            required: (value) => !!value || 'Obligatoire.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Email invalide.';
            },
        },
    }),
    methods: {
        async login(e) {
            e.preventDefault();
            const vm = this;
            const response = await vm.$store.dispatch('LoginByEmail', {
                email: vm.model.email,
                password: vm.model.password,
                config: vm.$config,
                axios: vm.$axios
            });
            if ((response !== null) && (response.user.token !== undefined) && (response.user.token.length > 0)) {
                if ((vm.$router.history._startLocation !== undefined) && (vm.$router.history._startLocation.length > 0)) {
                    vm.$router.push(vm.$router.history._startLocation);
                } else {
                    vm.$router.push('/dashboard');
                }
            } else if (vm.model.email === '' || vm.model.password === '') {
                vm.showResult = true;
                vm.color = 'red';
                vm.result = 'Veuillez renseigner les champs nécessaires';
            } else {
                vm.showResult = true;
                vm.color = 'red';
                vm.result = 'Mot de passe ou adresse email incorrects';
            }
        }
    }
};
</script>
